import { ContainerBoxes } from '@assets/svg/DashboardIcons';
import Typography from '@components/dataDisplay/typography';
import TrackingForm from '@components/sharing/forms/tracking-form';
import useRequest from '@services/api';
import { MONTH_DAY_YEAR_FORMAT } from '@services/constants';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useContextUI } from 'states/ui';
import BoxContainer from '../@common/BoxContainer';
import CardContainer from '../@common/CardContainer';
import DropDownButton from '../@common/DropdownButton';

const EmptyCard = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-3">
    <div className="flex justify-center items-center rounded-full bg-semantic-success-50 w-[75px] h-[75px]">
      <ContainerBoxes />
    </div>
    <p className="text-center text-primary-navy-400 font-medium">No Active Container</p>
    <p className="text-center text-primary-grey-500">Your active containers will appear here</p>
  </div>
);

const ActiveContainersItem = ({ data }) => {
  const trackingResponse = data?.tracking_response?.[0];

  const url = `/tracking/${data.id}`;

  // do not show if status is searching
  if (trackingResponse.Status === 'Searching') return null;

  return (
    <Link href={url} passHref>
      <div className="border rounded-lg  cursor-pointer hover:bg-neutral-white-500">
        <div className="flex relative justify-between border rounded-lg gap-4  mx-3 mt-5">
          <div className="p-5">
            <span
              className={
                'border py-1 px-2 rounded bg-semantic-progress-50 text-semantic-progress-500 text-sm'
              }
            >
              {trackingResponse?.Status}
            </span>
            <div className="mt-2">
              <Typography>{data?.container_number}</Typography>
            </div>
            <div className="mt-1">
              <Typography variant="text-sm" className="text-primary-navy-400">
                Arrival Date:{' '}
                {dayjs(trackingResponse?.ArrivalDate?.Date).format(MONTH_DAY_YEAR_FORMAT)}
              </Typography>
            </div>
          </div>

          <Image
            className="scale-75 origin-right"
            src={'/img/tracking-card.png'}
            alt="containers"
            layout="fill"
          />
          {/* </span> */}
        </div>
        <div className="mx-3 my-4">
          <div className="flex justify-between items-center">
            <div className="w-3.5 h-3.5 bg-gray-500 rounded-full border-inherit "></div>
            <div className="border w-full h-0 border-dashed"></div>
            <div className="w-3.5 h-3.5 bg-semantic-success-500 rounded-full border-inherit"></div>
          </div>
          <div className="flex justify-between items-center">
            <div className="grid mt-2">
              <Typography variant="h3" className="text-primary-grey-500">
                {trackingResponse?.Pol}
              </Typography>
              <Typography variant="text-sm" className="text-primary-navy-400">
                {trackingResponse?.FromCountry}
              </Typography>
            </div>
            <div className="grid mt-2 text-right">
              <Typography variant="h3" className="text-primary-grey-500">
                {trackingResponse?.Pod}
              </Typography>
              <Typography variant="text-sm" className="text-primary-navy-400">
                {trackingResponse?.ToCountry}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const ActiveContainers = () => {
  const { data: { data = [], pagination = {} } = {} } = useRequest.shipment_trackings.get({
    options: { keepPreviousData: true }
  });
  const [_, { openPortal }] = useContextUI();

  const recentTrackings = data?.slice(0, 8);
  const isEmpty = pagination?.count === 0;

  const EmptyStatusComponent = isEmpty ? <EmptyCard /> : null;

  const onClick = () =>
    openPortal({
      portal: 'modal',
      Component: <TrackingForm />
    });

  return (
    <CardContainer
      cardName={'Active Containers'}
      EmptyCard={EmptyStatusComponent}
      ActionButton={<DropDownButton onAddAction={onClick} />}
    >
      <BoxContainer>
        {recentTrackings?.map((item, idx) => (
          <ActiveContainersItem key={idx} data={item} />
        ))}
      </BoxContainer>
    </CardContainer>
  );
};

export default ActiveContainers;
