import { Tasks } from '@assets/svg/DashboardIcons';
import RenderIf from '@components/common/render-if';
import Edit from '@components/sharing/portals/portalsBody/Task/Edit';
import View from '@components/sharing/portals/portalsBody/Task/View';
import { CalendarIcon } from '@heroicons/react/outline';
import useRequest from '@services/api';
import { MONTH_DAY_FORMAT } from '@services/constants';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useContextUI } from 'states/ui';
import BoxContainer from '../@common/BoxContainer';
import CardContainer from '../@common/CardContainer';
import DropDownButton from '../@common/DropdownButton';

const EmptyCard = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-3">
    <div className="flex justify-center items-center rounded-full bg-semantic-success-50 w-[75px] h-[75px]">
      <Tasks />
    </div>
    <p className="text-center text-primary-navy-400 font-medium">No pending tasks</p>
    <p className="text-center text-primary-grey-500">Your pending task will appear here</p>
  </div>
);

const PendingTasksItem = ({ data }) => {
  const [_, { openPortal }] = useContextUI();

  const handleClickTask = () => {
    openPortal({
      portal: 'drawer',
      params: { taskId: data.id },
      Component: <View />
    });
  };

  const isOverDue = dayjs(data?.target_date).diff(new Date()) > 0;

  const className = classNames('uppercase px-1 rounded-sm border text-xs', {
    'bg-semantic-danger-50 text-semantic-danger-500 border-semantic-danger-100 ':
      data?.status === 'In Progress',
    'bg-[#E6F3F9] text-semantic-progress-500 border-semantic-progress-50':
      isOverDue && data?.status === 'In Progress',
    'bg-semantic-success-50 text-semantic-success-500 border-semantic-success-100':
      data?.status === 'Completed'
  });

  return (
    <div
      className="py-2 px-2 border text-gray-500  rounded-lg flex-col cursor-pointer hover:bg-neutral-white-500"
      onClick={handleClickTask}
    >
      <span className={className}>{data?.status}</span>
      <p className="text-primary-navy-400 text-sm">{data?.name}</p>

      <div className="flex items-center">
        <RenderIf isTrue={!!data?.shipment_name}>
          <p className=" text-xs font-light">{data?.shipment_name} </p>
        </RenderIf>
        &nbsp;
        <RenderIf isTrue={!!data?.target_date}>
          <div className="flex items-center border-0 border-l-2 pl-1 h-4">
            <div>
              <CalendarIcon className="h-3 w-3" />
            </div>
            &nbsp;
            <p className="text-gray-500 text-xs font-light">
              {dayjs(data?.target_date).format(MONTH_DAY_FORMAT)}
            </p>
          </div>
        </RenderIf>
      </div>
    </div>
  );
};

const PendingTasks = () => {
  const { data } = useRequest.tasks.get({
    params: { task_status: 'tasks_in_progress', task_assignee: 'All' }
  });
  const [_, { openPortal }] = useContextUI();

  const recentTasks = data?.data?.slice(0, 8);
  const isEmpty = data?.pagination?.count === 0;

  const handleClickTask = () => {
    openPortal({
      portal: 'modal',
      params: { mode: 'add' },
      Component: <Edit />
    });
  };

  return (
    <CardContainer
      cardName={'Pending Tasks'}
      EmptyCard={isEmpty ? <EmptyCard /> : null}
      ActionButton={<DropDownButton onAddAction={handleClickTask} />}
    >
      <BoxContainer>
        {recentTasks?.map((task, idx) => (
          <PendingTasksItem key={idx} data={task} />
        ))}
      </BoxContainer>
    </CardContainer>
  );
};

export default PendingTasks;
