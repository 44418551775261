import Container from '@components/common/container';
import Loading from '@components/loaders/loading';
import useOnboarding from '@hooks/use-onboarding';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import ActiveContainers from './components/ActiveContainers';
import PendingTasks from './components/PendingTasks';
import RecentDocuments from './components/RecentDocuments';

const DashboardHome: NextPage = () => {
  const { haveCreateAnyEntity, isLoading } = useOnboarding();

  const router = useRouter();

  if (isLoading) {
    return <Loading />;
  }

  if (!haveCreateAnyEntity && !isLoading) {
    router.push('/onboarding');
  }

  return (
    <Container>
      <div className="flex justify-center">
        <div className="flex items-center md:items-start gap-5 py-5 w-10/12">
          <div className="flex flex-col w-1/2 gap-5" style={{ height: '85vh' }}>
            <ActiveContainers />
          </div>
          <div className="flex flex-col w-1/2 gap-5" style={{ height: '85vh' }}>
            <PendingTasks />
            <RecentDocuments />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DashboardHome;
