import { withComponent } from '@components/@utils/withComponent';
import Typography from '@components/dataDisplay/typography';
import { FC, ReactNode } from 'react';

interface CardContainerProps {
  cardName: string;
  EmptyCard: ReactNode;
  ActionButton: ReactNode;
}

const CardContainer: FC<CardContainerProps> = ({ cardName, EmptyCard, ActionButton, children }) => {
  const Component = withComponent(EmptyCard, children);
  return (
    <div className="p-5 flex flex-col overflow-y-auto pb-0 bg-white stroke rounded-lg h-full">
      <div className="flex items-center">
        <Typography variant="h3">{cardName}</Typography>
        {ActionButton}
      </div>
      {Component}
    </div>
  );
};

export default CardContainer;
