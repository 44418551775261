import CloseIcon from '@assets/svg/CloseIcon';
import Typography from '@components/dataDisplay/typography';
import { Dropdown, DropdownBox } from '@components/navigation/dropdown';
import { FC, useRef, useState } from 'react';

interface DropDownButtonProps {
  DropdownOption?: JSX.Element;
  onAddAction?: () => void;
}
const DropDownButton: FC<DropDownButtonProps> = ({ DropdownOption, ...props }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const outsideClickRef = useRef();

  if (props.onAddAction) {
    return (
      <div className="relative flex-shrink-0 ml-auto cursor-pointer">
        <div
          onClick={props?.onAddAction}
          className="flex gap-2 bg-semantic-success-50 rounded-md px-1 py-0.5 border:semantic-success-100 hover:opacity-70 border border-semantic-success-200"
        >
          <Typography className="text-semantic-success-500" variant="text-xs">
            Add
          </Typography>
          <div className="w-5 h-5 flex items-center justify-center border border-semantic-success-200 rounded scale-75">
            <CloseIcon className="text-semantic-success-500  rotate-45" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dropdown
      isOpen={open}
      onOpen={setOpen}
      target={ref}
      className="relative flex-shrink-0 ml-auto"
    >
      <div
        onClick={props?.onAddAction}
        className="flex gap-2 bg-semantic-success-50 rounded-md px-1 py-0.5 border:semantic-success-100 hover:opacity-70 border border-semantic-success-200"
      >
        <Typography className="text-semantic-success-500" variant="text-xs">
          Add
        </Typography>
        <div className="w-5 h-5 flex items-center justify-center border border-semantic-success-200 rounded scale-75">
          <CloseIcon className="text-semantic-success-500  rotate-45" />
        </div>
      </div>

      <DropdownBox ref={outsideClickRef} className="bg-slate-400">
        <div className="absolute bg-white border border-gray-200 right-0 rounded-md shadow-lg overflow-hidden z-50 w-60 divide-y">
          {DropdownOption}
        </div>
      </DropdownBox>
    </Dropdown>
  );
};

export default DropDownButton;
