import { Documents, FileIcon } from '@assets/svg/DashboardIcons';
import PdfIcon from '@assets/svg/PdfIcon';
import GeneratedDocumentForm from '@components/sharing/forms/generate-document-form';
import UploadDocumentForm from '@components/sharing/forms/upload-document-form';
import useGetListByEntity from '@hooks/use-get-list-by-entity';
import { setCookie } from '@services/api/utils/cookies';
import downloadPdf from '@utils/download-pdf';
import { CookieList } from 'constants/cookies';
import { useRouter } from 'next/router';
import { useContextUI } from 'states/ui';
import BoxContainer from '../@common/BoxContainer';
import CardContainer from '../@common/CardContainer';
import DropDownButton from '../@common/DropdownButton';

const EmptyCard = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-3">
    <div className="flex justify-center items-center rounded-full bg-semantic-success-50 w-[75px] h-[75px]">
      <Documents />
    </div>
    <p className="text-center text-primary-navy-400 font-medium">No Recent Document</p>
    <p className="text-center text-primary-grey-500">Your recent document will appear here</p>
  </div>
);

const DropdownOption = () => {
  const [_, { openPortal }] = useContextUI();

  const onHandleClick = (props): { modal?: string } => {
    if (props.modal === 'generateDocument') {
      return openPortal({
        portal: 'modal',
        Component: <GeneratedDocumentForm />
      });
    }
    openPortal({
      portal: 'modal',
      Component: <UploadDocumentForm />
    });
  };

  const options = [
    {
      Icon: <FileIcon />,
      title: 'Generate Document',
      onClick: () => onHandleClick({ modal: 'generateDocument' })
    },
    {
      Icon: <FileIcon />,
      title: 'Upload Document',
      onClick: () => onHandleClick({ modal: 'uploadDocument' })
    }
  ];

  return (
    <div>
      {options.map(({ Icon, title, onClick }, idx) => (
        <div
          key={idx}
          className="flex items-center px-2 py-2.5 hover:bg-primary-grey-50 cursor-pointer"
          onClick={onClick}
        >
          <div className="flex items-center justify-center w-8 h-8">{Icon}</div>
          <p className="pl-2 text-primary-navy-400 text-sm">{title}</p>
        </div>
      ))}
    </div>
  );
};

const RecentDocumentsItem = ({ data }) => {
  const router = useRouter();
  const isGeneratedDocument = data.doc_class_name === 'GeneratedDocument';
  const isPdfGenerated = data.is_pdf_created;
  const isTaskDocument = data.doc_class_name === 'Milestone';

  const generatedDocumentUrl = `/documents/${data?.id}`;

  const setDocumentEntrypoint = () => {
    setCookie({ name: CookieList.DOCUMENT_ENTRYPOINT, value: router?.pathname });
  };

  const handleClickDocument = () => {
    if (isGeneratedDocument) {
      if (isPdfGenerated) {
        router.push(generatedDocumentUrl);
      } else {
        router.push(generatedDocumentUrl);
        setDocumentEntrypoint();
      }
    } else if (data?.is_pdf) {
      router.push(`/documents/${data?.id}/pdf`);
      setDocumentEntrypoint();
    } else if (isTaskDocument) {
      router.push(`/shipments/${data?.shipment_id}?current_tab=tasks&task_id=${data?.id}`);
    } else {
      downloadPdf(data?.download_path);
    }
  };

  return (
    <div
      className="px-3 py-2.5 bg-white hover:bg-neutral-white-500 stroke rounded cursor-pointer"
      onClick={handleClickDocument}
    >
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 bg-neutral-white-500 stroke flex items-center justify-center">
          <PdfIcon />
        </div>
        <div className="">
          <p className="text-primary-navy-400 text-sm link">{data?.document_name}</p>
          <p className="text-gray-500 text-sm link">{data?.Shipment?.name}</p>
        </div>
      </div>
    </div>
  );
};

const RecentDocuments = () => {
  const { data } = useGetListByEntity({ entity: 'document' });

  const recentDocuments = data?.slice(0, 8);
  const isEmpty = data?.length === 0;

  const EmptyStatusComponent = isEmpty ? <EmptyCard /> : null;

  return (
    <CardContainer
      cardName={'Recent Documents'}
      EmptyCard={EmptyStatusComponent}
      ActionButton={<DropDownButton DropdownOption={<DropdownOption />} />}
    >
      <BoxContainer>
        {recentDocuments?.map((item, idx) => (
          <RecentDocumentsItem key={idx} data={item} />
        ))}
      </BoxContainer>
    </CardContainer>
  );
};

export default RecentDocuments;
