import React from 'react';

interface Props {
  className?: string;
  children?: any;
  el?: HTMLElement;
  clean?: boolean;
}

const Container: React.FC<Props> = ({ children, el = 'div', className = '' }) => {
  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> = el as any;

  return <Component className={className}>{children}</Component>;
};

export default Container;
