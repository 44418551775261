const downloadPdf = (url) => {
  const link = document.createElement('a');
  link.download = 'pdf';
  link.href = url;
  link.target = '_blank';
  link.download = 'true';
  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export default downloadPdf;
