export const ContainerBoxes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <path
        fill="#00A46F"
        d="M10.676 25.047H6.26a.937.937 0 1 0 0 1.875h4.416a.938.938 0 0 0 0-1.875Z"
      />
      <path
        fill="#00A46F"
        d="M29.812 15.063h-5.344V2.187A2.19 2.19 0 0 0 22.281 0H9.719A2.19 2.19 0 0 0 7.53 2.188v12.874H2.187A2.19 2.19 0 0 0 0 17.25v12.563A2.19 2.19 0 0 0 2.187 32h27.625A2.19 2.19 0 0 0 32 29.812V17.25a2.19 2.19 0 0 0-2.188-2.188Zm-8.02 1.874h3.478v2.784l-.924-.566a1.56 1.56 0 0 0-1.632.001l-.922.565v-2.784ZM17.738 1.876v2.784c-.971-.596-1.197-.796-1.738-.796-.54 0-.767.2-1.739.796V1.875h3.477Zm-8.332.313c0-.173.14-.313.313-.313h2.667v3.343c0 1.222 1.343 1.967 2.379 1.331L16 5.792l1.234.757c1.039.638 2.38-.112 2.38-1.332V1.876h2.667c.172 0 .312.14.312.313v12.874H9.406V2.188Zm.801 14.75v2.783l-.923-.566a1.56 1.56 0 0 0-1.632.001l-.922.565v-2.784h3.477ZM1.875 29.813V17.25c0-.172.14-.313.312-.313h2.668v3.343c0 1.222 1.343 1.968 2.378 1.332l1.236-.757 1.234.756c1.039.638 2.38-.111 2.38-1.331v-3.343h2.98v13.188H2.186a.313.313 0 0 1-.312-.313Zm28.25 0c0 .172-.14.312-.313.312H16.937V16.937h2.98v3.343c0 1.22 1.343 1.968 2.379 1.332l1.235-.757 1.234.756c1.04.638 2.38-.111 2.38-1.331v-3.343h2.667c.173 0 .313.14.313.313v12.563Z"
      />
      <path
        fill="#00A46F"
        d="M25.739 25.047h-4.417a.938.938 0 0 0 0 1.875h4.417a.938.938 0 0 0 0-1.875ZM13.792 11.86h4.416a.938.938 0 0 0 0-1.876h-4.416a.938.938 0 0 0 0 1.875Z"
      />
    </svg>
  );
};

export const Documents = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" fill="none">
      <path
        stroke="#00A46F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 21v4.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 1 25.5v-17A1.5 1.5 0 0 1 2.5 7H5c.681 0 1.35.057 2 .166M17 21h4.5a1.5 1.5 0 0 0 1.5-1.5V13c0-5.946-4.325-10.882-10-11.834C12.35 1.056 11.68 1 11 1H8.5A1.5 1.5 0 0 0 7 2.5v4.666M17 21H8.5A1.5 1.5 0 0 1 7 19.5V7.166M23 16v-2.5A4.5 4.5 0 0 0 18.5 9h-2A1.5 1.5 0 0 1 15 7.5v-2A4.5 4.5 0 0 0 10.5 1H9"
      />
    </svg>
  );
};

export const Tasks = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" fill="none">
      <path
        fill="#00A46F"
        fillRule="evenodd"
        d="M4.654 6.685h24.357c2.102 0 3.822 1.698 3.822 3.775v19.765c0 2.076-1.72 3.775-3.822 3.775H4.654C2.552 34 .833 32.3.833 30.225V10.46c0-2.077 1.72-3.775 3.821-3.775ZM7.31 0h19.045c1.132 0 2.06.916 2.06 2.035 0 1.12-.927 2.036-2.06 2.036H7.31c-1.132 0-2.06-.916-2.06-2.036S6.177 0 7.31 0Zm6.564 22.548 9.987-9.406a1.334 1.334 0 0 1 1.866.044l.894.927c.5.52.481 1.347-.045 1.844l-12.3 11.584c-.296.28-.666.4-1.072.35a1.283 1.283 0 0 1-.954-.6l-5.367-8.41a1.298 1.298 0 0 1 .413-1.8l1.096-.682a1.332 1.332 0 0 1 1.82.408l3.662 5.74Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const OnboardingTracking = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" fill="none">
      <mask id="a" width="49" height="49" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M.889.889h47.822V48.71H.89V.889Z" />
      </mask>
      <g
        stroke="#00A46F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        mask="url(#a)"
      >
        <path d="M12.07 6.831c0 3.919-2.986 8.868-4.45 11.059a.934.934 0 0 1-1.56-.012C4.604 15.63 1.59 10.531 1.59 6.831a5.24 5.24 0 0 1 10.48 0Z" />
        <path d="M6.83 4.711A2.238 2.238 0 1 1 4.592 6.95M46.635 42.047c.785-1.817 1.376-3.762 1.376-5.505a5.24 5.24 0 0 0-10.482 0c0 3.7 3.014 8.8 4.471 11.047a.933.933 0 0 0 1.56.012c.386-.578.878-1.348 1.393-2.24" />
        <path d="M45.008 36.653a2.238 2.238 0 1 1-4.476 0 2.238 2.238 0 0 1 4.476 0ZM11.443 28.328h-2.35a7.504 7.504 0 0 0 0 15.008h27.493M13.462 13.77h19.674a7.279 7.279 0 0 1 0 14.558h-2.547" />
        <path d="M26.82 34.594H15.415a.56.56 0 0 1-.559-.56V22.629c0-.31.25-.56.56-.56H26.82c.309 0 .559.25.559.56v11.407a.56.56 0 0 1-.56.559Z" />
        <path d="M23.203 27.273h-4.17V22.06h4.17v5.214Z" />
      </g>
    </svg>
  );
};

export const OnboardingDocument = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="48" fill="none">
      <path
        fill="#00A46F"
        d="M38.025 6.338h-4.033V2.305A2.311 2.311 0 0 0 31.688 0H2.305A2.311 2.311 0 0 0 0 2.305v36.873a2.311 2.311 0 0 0 2.305 2.304h4.033v4.033a2.311 2.311 0 0 0 2.304 2.305h19.589c1.266 0 3.06-.71 3.982-1.58l6.438-6.059c.923-.868 1.678-2.616 1.678-3.884l.001-27.655a2.312 2.312 0 0 0-2.305-2.304ZM6.337 8.642v31.112H2.305a.584.584 0 0 1-.577-.576V2.305c0-.313.264-.576.577-.576h29.383c.312 0 .576.263.576.576v4.033H8.642a2.311 2.311 0 0 0-2.305 2.304Zm31.13 30.28-6.439 6.06a3.06 3.06 0 0 1-.493.361v-5.301c0-.396.16-.752.423-1.017a1.436 1.436 0 0 1 1.017-.423h5.765c-.09.121-.181.233-.274.32Zm1.133-2.625c0 .171-.03.37-.078.576h-6.547a3.175 3.175 0 0 0-3.169 3.169v5.98c-.205.041-.403.07-.576.07H8.642a.583.583 0 0 1-.576-.577V8.642c0-.312.263-.576.576-.576h29.383c.313 0 .576.264.576.576v27.655Z"
      />
    </svg>
  );
};

export const OnboardingContact = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none">
      <mask id="a" width="48" height="48" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" stroke="#fff" strokeWidth="1.5" d="M47.07 47.07V.75H.75v46.32h46.32Z" />
      </mask>
      <g
        stroke="#00A46F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        mask="url(#a)"
      >
        <path d="M9.9 22.31a7.659 7.659 0 1 1 15.318 0 7.659 7.659 0 0 1-15.318 0Z" />
        <path d="M21.855 45.953H5.604c-2.534 0-4.333-2.469-3.557-4.881 2.061-6.408 8.378-11.045 15.471-11.045 3.384 0 6.52 1.055 9.1 2.854M29.234 9.529a7.659 7.659 0 1 1 15.317 0 7.659 7.659 0 0 1-15.317 0Z" />
        <path d="M25.276 22.234a15.882 15.882 0 0 1 11.576-4.987c3.383 0 6.52 1.055 9.1 2.855M38.947 45.953v-14.01M45.952 38.953h-14.01" />
      </g>
    </svg>
  );
};

export const OnboardingTask = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="48" fill="none">
      <path
        fill="#00A46F"
        d="M35.433 3.398h-1.9V1.086a1.085 1.085 0 1 0-2.17 0v2.323H9.444V1.086a1.085 1.085 0 1 0-2.172 0v2.323H5.428A5.43 5.43 0 0 0 0 8.836v33.556a5.43 5.43 0 0 0 5.428 5.428h29.995a5.43 5.43 0 0 0 5.428-5.428V8.826a5.428 5.428 0 0 0-5.418-5.428Zm3.257 38.983a3.266 3.266 0 0 1-3.257 3.257H5.428A3.266 3.266 0 0 1 2.17 42.38V8.826a3.266 3.266 0 0 1 3.257-3.257h1.845v1.683a1.085 1.085 0 1 0 2.172 0V5.569h21.917v1.683a1.086 1.086 0 0 0 2.171 0V5.569h1.9a3.266 3.266 0 0 1 3.257 3.257V42.38Z"
      />
      <path
        fill="#00A46F"
        d="m13.809 23.014-4.006 3.821-1.846-1.846a1.082 1.082 0 1 0-1.53 1.531l2.594 2.595c.204.205.482.319.77.315.281.002.55-.107.75-.304l4.766-4.56a1.08 1.08 0 1 0-1.498-1.552ZM33.675 24.992H21.19a1.086 1.086 0 1 0 0 2.171h12.484a1.085 1.085 0 1 0 0-2.17ZM13.809 12.162l-4.006 3.821-1.846-1.845a1.082 1.082 0 1 0-1.53 1.53l2.594 2.595c.204.205.482.319.77.315.281.003.55-.107.75-.304l4.766-4.56a1.08 1.08 0 1 0-1.498-1.552ZM33.675 14.133H21.19a1.086 1.086 0 1 0 0 2.171h12.484a1.085 1.085 0 1 0 0-2.171ZM13.809 33.873l-4.006 3.821-1.846-1.845a1.082 1.082 0 1 0-1.53 1.53l2.594 2.595c.204.205.482.319.77.315.281.003.55-.107.75-.304l4.766-4.56a1.08 1.08 0 1 0-1.498-1.552ZM33.675 35.844H21.19a1.085 1.085 0 1 0 0 2.17h12.484a1.085 1.085 0 1 0 0-2.17Z"
      />
    </svg>
  );
};

export const OnboardingShipment = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none">
      <path
        fill="#00A46F"
        d="M47.602 27.121 42.3 21.82l-2.694-1.54a.747.747 0 1 0-.741 1.297l1.484.849-16.438 9.59-16.439-9.59 1.502-.858a.747.747 0 0 0-.741-1.297L5.52 21.82l-5.302 5.3a.746.746 0 0 0 .153 1.175l4.859 2.82v5.5c0 .265.14.511.37.645l17.933 10.46c.019.011.039.016.058.025.02.01.04.017.06.025.084.032.17.053.258.053a.734.734 0 0 0 .319-.078c.02-.01.04-.014.058-.024l17.933-10.46c.23-.135.37-.381.37-.646v-5.5l4.859-2.821a.746.746 0 0 0 .153-1.174ZM6.098 23.355l16.598 9.682-4.108 4.108-16.626-9.654 4.136-4.136Zm.627 8.63 11.455 6.652c.14.133.324.198.508.198a.78.78 0 0 0 .55-.227l3.925-3.925v11.092L6.725 36.187v-4.202Zm34.37 4.202-16.437 9.588V34.683l3.955 3.955a.711.711 0 0 0 .506.212.757.757 0 0 0 .522-.214l11.455-6.652v4.203Zm-11.862.958-4.108-4.108 16.598-9.682 4.136 4.137-16.626 9.653ZM29.966 19.213a.748.748 0 0 0 1.016.291l3.004-1.666a.747.747 0 1 0-.726-1.306l-3.004 1.666c-.359.2-.49.654-.29 1.015ZM30.621 16.606c.123 0 .247-.03.362-.094l3.003-1.666a.747.747 0 1 0-.725-1.306l-3.004 1.666a.746.746 0 0 0 .364 1.4Z"
      />
      <path
        fill="#00A46F"
        d="m10.845 20.08 4.035 2.241a.747.747 0 0 0 .726-1.306l-3.65-2.028V8.713l11.207 5.934v2.538h-2.241a.747.747 0 0 0-.748.747v4.483h-2.241a.747.747 0 0 0-.528 1.276l5.977 5.977a.745.745 0 0 0 1.057 0l5.977-5.977a.747.747 0 0 0-.528-1.276h-2.242v-4.483a.747.747 0 0 0-.747-.747h-2.241v-2.539l11.208-5.933v10.274l-3.636 2.02a.746.746 0 1 0 .727 1.305l4.019-2.232a.747.747 0 0 0 .384-.653V7.472a.748.748 0 0 0-.398-.661L24.26.086a.752.752 0 0 0-.7 0L10.86 6.811a.748.748 0 0 0-.398.66v11.956c0 .27.147.521.384.653Zm16.054 3.83h1.185l-4.174 4.174-4.173-4.174h1.185a.747.747 0 0 0 .747-.747V18.68h4.483v4.483c0 .413.335.747.747.747Zm8.116-16.438L23.91 13.35l-5.004-2.65 11.102-5.88 5.007 2.652ZM23.91 1.592l4.5 2.383L17.31 9.857l-4.504-2.385 11.105-5.88Z"
      />
    </svg>
  );
};

export const Truck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" fill="none">
      <path
        fill="#364965"
        d="m17.962 5.986-1.26-1.809a1.244 1.244 0 0 0-1.02-.533H11.46v-2.4C11.46.557 10.902 0 10.217 0H3.078c-.685 0-1.243.558-1.243 1.243V8.56h-.592C.558 8.56 0 9.117 0 9.802v3.357c0 .685.558 1.243 1.243 1.243h.945A2.135 2.135 0 0 0 4.252 16c.99 0 1.826-.68 2.063-1.598h5.924A2.135 2.135 0 0 0 14.303 16c.993 0 1.83-.683 2.065-1.604l.702-.001a1.245 1.245 0 0 0 1.114-1.236V6.697c0-.256-.077-.501-.222-.71ZM5.659 1.066h1.977v1.581l-.525-.321a.887.887 0 0 0-.928 0l-.524.321V1.065ZM4.252 14.933c-.59 0-1.066-.48-1.066-1.065s.476-1.065 1.066-1.065c.589 0 1.065.48 1.065 1.065s-.476 1.066-1.065 1.066Zm6.143-1.598h-4.08a2.134 2.134 0 0 0-2.063-1.598c-.991 0-1.827.68-2.064 1.598h-.945a.178.178 0 0 1-.178-.177V9.802c0-.097.08-.177.178-.177h9.152v3.711Zm0-4.777H2.9V1.243c0-.098.08-.178.177-.178h1.516v1.9a.888.888 0 0 0 1.352.757l.702-.43.701.43a.888.888 0 0 0 1.352-.757v-1.9h1.516c.098 0 .178.08.178.178V8.56Zm3.323-3.85h1.964c.058 0 .112.03.146.077l1.259 1.809c.02.03.032.065.032.102V8.49h-3.4V4.71Zm-2.258 0h1.193v3.85H11.46V4.71Zm2.843 10.226a1.067 1.067 0 0 1 0-2.13c.59-.001 1.065.48 1.065 1.064 0 .586-.477 1.066-1.065 1.066Zm2.755-1.644-.702.007a2.135 2.135 0 0 0-2.053-1.56c-.992 0-1.827.68-2.064 1.598h-.779V9.625h5.659c0 3.825.026 3.592-.06 3.666Z"
      />
    </svg>
  );
};

export const Pin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="none">
      <path
        fill="#364965"
        d="M11.593 3.057c-2.381-4.078-8.385-4.074-10.764 0a5.968 5.968 0 0 0-.107 5.88c.02.034-.233-.334 4.46 6.471.45.79 1.607.789 2.057 0 4.692-6.803 4.441-6.436 4.46-6.472 1-1.856.96-4.054-.106-5.88Zm-.707 5.413c-4.69 6.8-4.44 6.434-4.458 6.469-.09.166-.345.166-.434 0-.02-.035.233.333-4.458-6.47a5.036 5.036 0 0 1 .102-4.94c2.02-3.457 7.127-3.456 9.146 0a5.036 5.036 0 0 1 .102 4.94Z"
      />
      <path
        fill="#364965"
        d="M8.558 2.79H3.863a.937.937 0 0 0-.936.935V8.42c0 .516.42.936.936.936h4.695c.516 0 .936-.42.936-.936V3.725a.937.937 0 0 0-.936-.936Zm-2.85.937h1.005v1.266c-.154-.095-.28-.188-.502-.188-.224 0-.35.094-.503.188V3.727ZM3.865 8.42l-.002-4.693h.908v1.698c0 .549.603.874 1.059.593l.38-.234.381.234a.696.696 0 0 0 1.06-.593v-1.7h.906l.001 4.693-4.693.002Z"
      />
    </svg>
  );
};

export const FileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" fill="none">
      <path
        stroke="#364965"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.375 8.313V6.78a1.969 1.969 0 0 0-1.969-1.968h-.875a.656.656 0 0 1-.656-.657v-.875a1.969 1.969 0 0 0-1.969-1.969H2.812M5 6.563v3.5m1.75-1.75h-3.5m.875-7H1.281a.656.656 0 0 0-.656.657V12.03c0 .363.294.656.656.656H8.72a.656.656 0 0 0 .656-.656V6.562c0-2.899-2.35-5.25-5.25-5.25Z"
      />
    </svg>
  );
};
