import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { queryFetch } from '@services/api/utils/query-fetch';
import { useQueries } from 'react-query';

const useOnboarding = () => {
  const results = useQueries([
    { queryKey: [API_ENDPOINTS.CONTACTS], queryFn: queryFetch },
    { queryKey: [API_ENDPOINTS.SHIPMENTS], queryFn: queryFetch },
    { queryKey: [API_ENDPOINTS.GENERATED_DOCUMENTS], queryFn: queryFetch },
    { queryKey: [API_ENDPOINTS.TASKS, { task_assignee: 'All' }], queryFn: queryFetch },
    { queryKey: [API_ENDPOINTS.PRODUCTS], queryFn: queryFetch },
    { queryKey: [API_ENDPOINTS.SHIPMENT_TRACKINGS], queryFn: queryFetch }
  ]);

  const isLoading = results.map((x) => x.isLoading).some((y) => y === true);

  const data = results
    .map((x) => x?.data) // data response
    .map((y) => y?.data); // data array

  const haveCreateAnyEntity = data.some((z, i) =>
    // 0 & 4 are the contact and product array positions. Onboarding
    [0, 4].includes(i) ? z?.length > 3 : z?.length > 1
  );
  const haveCreatedAllEntities = data.every((z, i) =>
    [0, 4].includes(i) ? z?.length > 3 : z?.length > 1
  );

  return {
    haveCreatedAllEntities,
    haveCreateAnyEntity,
    data,
    isLoading
  };
};

export default useOnboarding;
